@import '../../base.less';

.yph-footer {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  flex: 0 0 auto;

  &-contact {
    font-size: @font-normal;
    color: @grey-9;
    margin-bottom: 24px;
  }

  &-copy {
    font-size: @font-sm;
    color: @grey-9;
  }


}
@primary-color: #05C69A;