@import '../../base.less';

.yph-login-qr {
  text-align: center;
  position: relative;

  img {
    width: 200px;
    height: 200px;
  }


  &-timeout-msg {
    position: absolute;
  }

  &-timeout-mask {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .9);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-timeout-msg-container {
    width: 200px;
    height: 200px;
    margin: 0 auto;
    position: relative;

  }
}
@primary-color: #05C69A;